//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import snarkdown from 'snarkdown'
const savePixels = require('save-pixels')
// const getPixels = require('get-pixels')
const ndarray = require('ndarray')
const adaptiveThreshold = require('adaptive-threshold')
const EXIF = require('exif-js')

export default {
  props: [
    'fieldCoord',
    'dict'
  ],
  data () {
    return {
      croppedPic: null,
      processingImage: false,
      webCamPic: null,
      thresholdedPic: null,
      webCamCapture: false,
      sigBox: null,
      size: 24,
      compensation: 7,
      inputCaptureSupported: false,
      webcamCaptureError: false,
      metadata: null,
      blurred: null,
      inputAttrs: {capture: true, class: 'file-input'},
      imageNotSetClass: { background: 'whitesmoke', maxWidth: '640px', width: '100%', paddingBottom: '25%', position: 'relative' },
      buttonClass: {
        button: true,
        'is-primary': true,
        'is-normal': true,
        'is-fullwidth': true
      },
      croppaContainerBoundingClientRect: null,
    }
  },
  computed: {
    showCodeFragmentMark () {
      return this.$store.state.showCodeFragmentMark
    },
    cropperPlaceholder () {
      return this.processingImage ? '...' : (this.canCaptureImage ? this.dict.G34 : this.dict.G37)
    },
    signatureBoxBoundingClientRect () {
      return JSON.stringify(this.croppaContainerBoundingClientRect)
    },
    signatureBoxDim () {
      /**
       * 2024-01-09 John Yee
       * Box to add an image of the voter signature:
       *  The red-outline box is inside a taller png image.
       *  I created a signature image twice the height of the red-outline box.  The image has 
       *  a white border that is the same height above and below the red-outline box i.e.
       *  the red-outline box has symmetric padding above and below.
       * 
       *  The dimensions are height=141px and width=713px.
       *  I hardcode these values.
       *  Ideally these dimensions should be extracted from the image file itself.
       *  A complication - The red-outline box (representing the FPCA's signature box) is
       *  padded above and below to allow "signing" outside the lines.  The code needs the
       *  size of this padding; but can't get that from the image file itself.
       * 
       *  I am not working on these coding features; I have to get this code working last month.
       */
      const blankSignatureBoxDims = { height: 141, width: 713 }

      let scaleFactor = !!this.croppaContainerBoundingClientRect ?
                        this.croppaContainerBoundingClientRect.width / blankSignatureBoxDims.width : 1.0
      let sigHeight = blankSignatureBoxDims.height * scaleFactor

       // if scaled height of the signature image is bigger than the croppa container height,
       // then recalculate the scale factor based on the height
      if (!!this.croppaContainerBoundingClientRect && sigHeight>this.croppaContainerBoundingClientRect.height) {
        scaleFactor = this.croppaContainerBoundingClientRect.height / blankSignatureBoxDims.height
      }

      sigHeight = blankSignatureBoxDims.height * scaleFactor
      const sigWidth = blankSignatureBoxDims.width * scaleFactor
      return {sigHeight, sigWidth}
    },
    device () { return this.$store.state.userdata.device },
    ...mapState({
      canCaptureImage: state => state.userdata.device.hasWebCam
    })
  },
  methods: {
    md (md) { return snarkdown(md) },
    getCroppaContainerBoundingClientRect () {
      /**
       * https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
       */
      // const croppaContainer = document.getElementsByClassName("croppa-container croppa--has-target croppa--disabled-cc")
      const croppaContainer = document.getElementsByClassName("croppa-container")
      if (croppaContainer.length>0) {
        return croppaContainer[0].getBoundingClientRect()
      } else {
        return null
      }
    },
    handleFileTypeMismatch () {
      this.processingImage = false
      this.clearImage()
    },
    clearImage () {
      this.webCamPic = null
      this.thresholdedPic = null
      this.croppedPic.remove()
    },
    async uploadPic () {
      this.inputAttrs = {class: 'file-input'}
      this.clearImage()
      this.croppedPic.refresh()
      // await this.$nextTick()
      this.croppedPic.chooseFile()
      setTimeout(() => {
        this.inputAttrs = {capture: true, class: 'file-input'}
        this.croppedPic.refresh()
      }, 500)
    },
    captureWebcamImage () {
      this.processingImage = true
      this.$nextTick()
        .then(() => {
          if (this.$refs && this.$refs.webcam) this.$refs.webcam.takePhoto()
        })
        .catch(e => { console.error('captureWebcamImage error', e) })
    },
    handleFileSizeExceed () {
      this.$buefy.dialog.alert({
        title: 'Image too large',
        message: 'That image is too large, please choose a smaller image.',
        type: 'is-danger'
      })
    },
    captureError () {
      this.webcamCaptureError = true
    },
    useSignature () {
      this.$emit('sigcap', this.croppedPic.generateDataUrl())
    },
    startCameraFilePicker () {
      if (!this.croppedPic || !this.croppedPic.hasImage()) {
        if (this.device && !this.device.inputCaptureSupported) {
          this.thresholdedPic = null
          this.webCamCapture = true
        } else { this.captureSignature() }
      }
    },
    captureSignature () {
      this.croppedPic.chooseFile()
    },
    rotate (val) {
      this.croppedPic.rotate(val)
    },
    move (dir) {
      let speed = 10
      switch (dir) {
        case 'up':
          this.croppedPic.moveUpwards(speed)
          break
        case 'down':
          this.croppedPic.moveDownwards(speed)
          break
        case 'left':
          this.croppedPic.moveLeftwards(speed)
          break
        case 'right':
          this.croppedPic.moveRightwards(speed)
          break
      }
    },
    zoom (dir) {
      switch (dir) {
        case 'in':
          this.croppedPic.zoomIn()
          break
        case 'out':
          this.croppedPic.zoomOut()
          break
      }
    },
    increaseCompensation () {
      this.processingImage = true
      this.$nextTick()
        .then(() => {
          this.metadata = this.croppedPic.getMetadata()
          this.thresholdedPic = null
          this.compensation = this.compensation + 3
          this.size = this.size + 3
          this.drawThresholdToCanvas(null)
        })
        .catch(e => { console.error('increaseCompensation error', e) })
    },
    decreaseCompensation () {
      this.processingImage = true
      this.$nextTick()
        .then(() => {
          this.metadata = this.croppedPic.getMetadata()
          this.thresholdedPic = null
          this.compensation = this.compensation - 3
          this.size = this.size - 3
          this.drawThresholdToCanvas(null)
        })
        .catch(e => { console.error('decreaseCompensationError', e) })
    },
    increaseSize () {
      this.metadata = this.croppedPic.getMetadata()
      this.size = this.size + 1
      this.drawThresholdToCanvas()
    },
    drawFromFile (file) {
      this.processingImage = true
      this.$nextTick()
        .then(() => {
          let needsRotation
          EXIF.getData(file, function () { needsRotation = (EXIF.getTag(this, 'Orientation')) > 4 })
          this.thresholdedPic = null
          let reader = new FileReader()
          reader.onload = () => {
            let img = new Image()
            img.onload = () => {
              let maxWidth = 1280
              let maxHeight = 720
              let width = img.width
              let height = img.height
              let isTooLarge = false

              if (width >= height && width > maxWidth) {
                height *= maxWidth / width
                width = maxWidth
                isTooLarge = true
              } else if (height > maxHeight) {
                width *= maxHeight / height
                height = maxHeight
                isTooLarge = true
              }

              if (!isTooLarge) {
                // return file here
                this.webCamPic = reader.result
                this.drawThresholdToCanvas(reader.result)
              } else {
                let canvas = document.createElement('canvas')
                canvas.width = width
                canvas.height = height
                let ctx = canvas.getContext('2d')
                ctx.drawImage(img, 0, 0, width, height)
                if (width < height || needsRotation) {
                  ctx.rotate(-Math.PI / 2)
                }
                this.drawThresholdToCanvas(canvas.toDataURL())
                ctx = null
                canvas = null
              }
            }
            img.src = reader.result
          }
          reader.readAsDataURL(file)
        })
        .catch(e => { console.error('drawFromFileError', e) })
    },
    async drawThresholdToCanvas (imgUrl) {
      function getPix (imgUrl) {
        return new Promise((resolve, reject) => {
          let img = new Image()
          img.onload = function () {
            var canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            let ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0)
            let pixels = ctx.getImageData(0, 0, img.width, img.height)
            let arr = ndarray(new Uint8Array(pixels.data), [img.width, img.height, 4], [4, 4 * img.width, 1], 0)
            ctx = null
            canvas = null
            resolve(arr)
          }
          img.onerror = function (err) {
            console.error('img.onload error', err)
            reject(err)
          }
          img.src = imgUrl
        })
      }
      this.processingImage = true
      this.$nextTick()
        .then(async () => {
          if (!this.thresholdedPic) {
            this.webCamCapture = false
            this.webCamPic = imgUrl || this.webCamPic
            let pixels
            try {
              pixels = await getPix(this.webCamPic)
            } catch (e) {
              console.error('await getPixels', e)
            }

            /**
             * 2023-12-27 John Yee
             * 
             * https://github.com/FujiHaruka/node-adaptive-threshold
             * 
             * https://stackoverflow.com/questions/44737011/save-pixels-leading-to-corrupted-and-desaturated-images-on-node-js
             * 
             */

            let thresholded = adaptiveThreshold(pixels, {size: this.size, compensation: this.compensation})
            let cnv = savePixels(thresholded, 'canvas') // returns canvas element
            let ctx = cnv.getContext('2d')
            let imgData = ctx.getImageData(0, 0, cnv.width, cnv.height)
            for (let x = 3; x < imgData.data.length; x += 4) {
              imgData.data[x] = Math.abs(255 - imgData.data[x - 1])
            }
            ctx.putImageData(imgData, 0, 0)
            this.thresholdedPic = cnv.toDataURL()
            ctx = null
            cnv = null
            this.croppedPic.refresh()
            this.processingImage = false
          } else {
            this.croppedPic.refresh()
            this.processingImage = false
          }
        })
        .catch(e => { console.error('nextTick adaptiveThreshold error', e) })
    },
    onDraw: function (ctx) {
      ctx.save()
      ctx.globalAlpha = 0.9
      ctx.restore()
      if (this.metadata) this.croppedPic.applyMetadata(this.metadata)
      this.metadata = null
    },
    editImg: function (imgData, canvasWidth, canvasHeight, lowerBound, upperBound) {
      var data = imgData.data.slice()
      let monoData = []
      for (var i = 0; i < data.length; i += 4) {
        monoData[i / 4] = Math.floor((data[i] + data[i + 1] + data[i + 2]) / 3)
      }
      let sorted = monoData.slice().sort()
      let lowerBoundPix = sorted[Math.floor(lowerBound / 100 * monoData.length)]
      let upperBoundPix = sorted[Math.floor(upperBound / 100 * monoData.length)]

      for (let i = 0; i < monoData.length; i++) {
        let adj
        if (monoData[i] < lowerBoundPix) {
          adj = 0
        } else if (monoData[i] > upperBoundPix) {
          adj = 255
        } else {
          adj = Math.floor(255 - ((upperBoundPix - monoData[i]) / (upperBoundPix - lowerBoundPix) * 255))
        }
        var alpha = 255 - adj
        data[i * 4] = adj // red
        data[i * 4 + 1] = adj // green
        data[i * 4 + 2] = adj // blue
        data[i * 4 + 3] = alpha // alpha
      }
      var imgd = new ImageData(1200, 800)
      imgd.data.set(data)
      return imgd
    }
  },
  mounted () {
    this.sigBox = new Image()
    this.croppaContainerBoundingClientRect = this.getCroppaContainerBoundingClientRect ()
  }
}
